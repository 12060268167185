(function (jQuery) {

    $.fn.news = function (config) {
        var defaults = {
            width: '100%',
            element: 'news',
            effect: 'fade',
            autoplay: false,
            timer: 4000
        };
        var config = $.extend(defaults, config);

        return this.each(function () {
            config.element = $('#' + $(this).attr('id'));
            var timername = config.element;
            var active = 0;
            var previous = 0;
            var count = config.element.find('ul li').length;
            var changestate = true;

            if (config.feed === false) {
                config.element.find('ul li').eq(active).fadeIn();
            }
            changeNews();
            resizeEvent();

            if (config.autoplay)
            {
                timername = setInterval(function () {
                    autoPlay()
                }, config.timer);
                $(config.element).on('mouseenter', function () {
                    clearInterval(timername);
                });

                $(config.element).on('mouseleave', function () {
                    timername = setInterval(function () {
                        autoPlay();
                    }, config.timer);
                });
            } else
            {
                clearInterval(timername);
            }

            $(window).on('resize', function () {
                resizeEvent();
            });

            config.element.find('.navigation span').on('click', function () {
                if (changestate)
                {
                    changestate = false;
                    if ($(this).index() === 0)
                    {
                        active--;
                        if (active < 0)
                            active = count - 1;

                        changeNews();
                    } else
                    {
                        active++;
                        if (active === count)
                            active = 0;

                        changeNews();
                    }
                }
            });

            function resizeEvent()
            {
                if (config.element.width() < 480)
                {
                    config.element.find('.title h2').css({'display': 'none'});
                    config.element.find('.title').css({'width': 10});
                    config.element.find('ul').css({'left': 30});
                } else
                {
                    config.element.find('.title h2').css({'display': 'inline-block'});
                    config.element.find('.title').css({'width': 'auto'});
                    config.element.find('ul').css({'left': $(config.element).find('.title').width() + 30});
                }
            }

            function autoPlay()
            {
                active++;
                if (active === count)
                    active = 0;

                changeNews();
            }

            function changeNews()
            {
                if (config.effect === 'fade')
                {
                    config.element.find('ul li').css({'display': 'none'});
                    config.element.find('ul li').eq(active).fadeIn('normal', function () {
                        changestate = true;
                    });
                } else if (config.effect === 'slide-h')
                {
                    config.element.find('ul li').eq(previous).animate({width: 0}, function () {
                        $(this).css({'display': 'none', 'width': '100%'});
                        config.element.find('ul li').eq(active).css({'width': 0, 'display': 'block'});
                        config.element.find('ul li').eq(active).animate({width: '100%'}, function () {
                            changestate = true;
                            previous = active;
                        });
                    });
                } else if (config.effect === 'slide-v')
                {
                    if (previous <= active)
                    {
                        config.element.find('ul li').eq(previous).animate({top: -60});
                        config.element.find('ul li').eq(active).css({top: 60, 'display': 'block'});
                        config.element.find('ul li').eq(active).animate({top: 0}, function () {
                            previous = active;
                            changestate = true;
                        });
                    } else
                    {
                        config.element.find('ul li').eq(previous).animate({top: 60});
                        config.element.find('ul li').eq(active).css({top: -60, 'display': 'block'});
                        config.element.find('ul li').eq(active).animate({top: 0}, function () {
                            previous = active;
                            changestate = true;
                        });
                    }
                }
            }
        });
    };
})(jQuery);

